.gustavo {
    min-height: 90vh;
    display: flex;
    justify-content: center;
    .gustavo--body {
        width: 70%;
        .settings {
            display: flex;
            flex-direction: column;
            padding-top: 3em;
            padding-bottom: 3em;
            gap: 1em;
        }
    }
}
.content {
    padding: 0;
    .reaction-gifs {
        display: flex;
        flex-direction: column;
        gap: 2em;
        .reaction-gifs--global {
            display: flex;
            flex-direction: column;
            align-items: center;
            .global-gif--header {
                display: flex;
                flex-direction: column;
                padding-bottom: 2vh;
                .reaction-chance {
                    display: flex;
                    justify-content: center;
                    .reaction-chance--chance {
                        margin-left: 1em;
                        border-radius: 3px;
                        width: 3rem;
                        color: #fff;
                        text-align: center;
                        font-weight: 600;
                        font-size: 16px;
                    }
                }
                
            }
            .global-gifs--elements{
                display:grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                row-gap: 2ch;
                column-gap: 2ch;
                max-width: 100%;
                .global-reaction--gif {
                    max-width: 100%;
                    min-height: 0vh;
                    max-height: 50vh;
                    overflow: hidden;
                    cursor: pointer;
                }
            }
            
        }
        .reaction-gifs--personal {
            display: flex;
            flex-direction: column;
            align-items: center;
            .personal-gif--header {
                display: flex;
                flex-direction: column;
                padding-bottom: 2vh;
                .reaction-chance {
                    display: flex;
                    justify-content: center;
                    .reaction-chance--chance {
                        margin-left: 1em;
                        border-radius: 3px;
                        width: 3rem;
                        color: #fff;
                        text-align: center;
                        font-weight: 600;
                        font-size: 16px;
                    }
                }
            }
            .personal-gifs--elements {
                display: flex;
                flex-direction: column;
                gap: 0.5vw;
                .personal-gifs-container {
                    .personal-gif-element {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5vw;
                        .personal-gif--username {
                            text-align: center;
                        }
                        .personal-gif--gifs {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            row-gap: 2ch;
                            column-gap: 2ch;
                            max-width: 100%;
                            .personal-reaction--gif {
                                max-width: 100%;
                                min-height: 0vh;
                                max-height: 50vh;
                                overflow: hidden;
                                cursor: pointer;
                            }
                        }
                    }
                }
                
            }
    
        }
    }
    .gustavo-persona {
        display: flex;
        flex-direction: column;
        .persona-text {
            color: white;
            font-size: 24px;
            height: 150px;
        }
    }
}
.add-reaction-gif-button {
    margin-top: 3em;
    width: 15em;
    border: 1px solid #949494;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    background-color: rgba($color: #000000, $alpha: 0.5);
}

.send-gif--button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
}

@media (max-width: 600px) {
    .gustavo {
        .gustavo--body {
            width: 90%;
        }
    }
    .content {
        padding: 0;
        .reaction-gifs {
            .reaction-gifs--global {
               .global-gifs--elements {
                    grid-template-columns: 1fr 1fr;
               } 
            }
            .reaction-gifs--personal {
                .personal-gifs--elements {
                    .personal-gifs-container {
                        .personal-gif-element {
                            .personal-gif--gifs {
                                grid-template-columns: 1fr 1fr;
                            }
                            .personal-gif--username {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

}