/* Header.scss */

.header {
    position: sticky;
    z-index: 10;
    top: 0px;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: rgba(#000000, 0.4);
    -webkit-box-shadow: 0px 3px 22px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 22px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 22px -6px rgba(0,0,0,0.75);

    .header--wave {
        position: absolute;
        width: 100%;
        margin-top: -10px;
        object-fit: fill;
    }

    .nav-logo {
        margin-left: 5vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;

        .logo--img {
            height: 30px;
        }

        .logo--text {
            font-weight: 600;
            font-size: 24px;
            color: #FFF;
        }
    }

    .nav {
        padding-right: 64px;
        display: flex;
        flex-direction: row;
        color: #fff;
        gap: 5vw;
        padding-right: 10vw;
        font-weight: 600;
        font-size: 19px;

        .gustavo-nav--button {
            cursor: pointer;
        }

        .birthday-nav--button {
            cursor: pointer;
        }

        .console-nav--button {
            cursor: pointer;
        }
    }
}

@media (max-width: 600px) {
    .header {
        flex-direction: column;
        height: auto;

        .nav-logo {
            margin-left: 0;
            padding-top: 10px;
            justify-content: center;

            .logo--text {
                font-size: 20px;
            }
        }

        .nav {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-right: 0;
            padding-bottom: 10px;
            column-gap: 20vw;
            row-gap: 2vh;
            align-items: center;
            font-size: 24px;
            
        }
    }
}
