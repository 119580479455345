.home {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .home-blur {
        width: 70vw;
        height: auto;
        background-color: rgba(#000000, 0.15);
        text-align: left;

        .home-page--title {
            font-size: 50px;
            font-weight: 600;
            padding-left: 80px;
            color: #fff;
        }

        .auth-buttons {
            display: flex;
            flex-direction: row;
            gap: 30px;
            padding-left: 80px;
            color: #fff;
            font-size: 20px;
            text-align: center;
            vertical-align: middle;
            padding-top: 30px;

            .login-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 235px;
                height: 70px;
                background-color: #44DAE7;
                border-radius: 8px;
                cursor: pointer;
                z-index: 3;
            }

            .signUp-button {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 120px;
                height: 70px;
                background-color: #34F7D5;
                border-radius: 8px;
                z-index: 3;
            }
        }

        .meme {
            width: 20vw;
            height: 53.5vh;
            position: relative;
            left: 50vw;
            border-radius: 30px;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 5;
        }

        .dancing-cats {
            z-index: 5;
            display: flex;
            position: relative;
            .cat-first {
                width: 100%;
                margin-bottom: -30px;
            }
    
            .cat-second {
                margin-bottom: -350px;
                margin-left: -330px;
                width: 100%;
                scale: 75%;
            }
        }
        .decorative-line {
            position: relative;
            left: -38px;
            top: -10vh;
            rotate: 25deg;
            z-index: -1;
            width: 71vw;
        }
    }

    .hamsters-facts--blur {
        display: flex;
        position: relative;
        z-index: 1;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background: linear-gradient(216.44deg, rgba(192, 169, 240, 0.1) -11.15%, rgba(192, 169, 240, 0.1) -1.04%);
        min-height: 700px;

        .blurred-things {
            position: relative;
            z-index: -2;
            filter: blur(15px);
    
            @keyframes levitate {
                0%, 100% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(-20px);
                }
            }
    
            .cylinder--1 {
                position: relative;
                left: -10vw;
                top: -400px;
                rotate: -35deg;
                max-width: 30vw;
                animation: levitate 4s ease-in-out infinite;
            }
    
            .cylinder--2 {
                position: relative;
                left: 0;
                top: -30vh;
                rotate: 25deg;
                animation: levitate 7s ease-in-out infinite;
            }
    
            .triangle--1 {
                position: relative;
                left: 0;
                top: -600px;
                rotate: -87deg;
                scale: 60%;
                animation: levitate 5s ease-in-out infinite;
            }
        }

        .hamsters-facts--page {
            width: 70%;
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 1;

            .hamsters-facts--title {
                padding-top: 70px;
                font-size: 48px;
                font-weight: 600;
                color: #131313;
            }

            .hamsters-facts--accordions {
                width: 70%;
                display: flex;
                flex-direction: column;
                gap: 30px;
                padding-top: 50px;
                padding-bottom: 50px;
            }
        }
    }
}

.login-modal {
    .login-modal--title {
        font-size: 60px;
        text-align: left;
        margin-top: 20px;
        margin-left: 20px;
    }  
}

@media (max-width: 600px) {
    .home {
        .home-blur {
            width: 90%;
            height: auto;
            padding: 20px;

            .home-page--title {
                font-size: 24px;
                padding-left: 0;
                text-align: center;
            }

            .auth-buttons {
                flex-direction: column;
                gap: 15px;
                padding-left: 0;
                .login-button, .signUp-button {
                    width: 100%;
                    height: 5vh;
                }
            }

            .meme {
                width: 90%;
                height: auto;
                position: relative;
                left: 30vw;
                top: 30vh;
                margin-top: 20px;
            }

            .dancing-cats {
                .cat-first {
                    position: relative;
                    top: 40px;
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 0;
                    bottom: -100px;
                }
                .cat-second {
                    position: relative;
                    top: 100px;
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 0;
                }
            }
        }
        .decorative-line {
            position: relative;
            left: -38px;
            top: 10vh;
            rotate: 25deg;
            z-index: -1;
            width: 71vw;
        }
        .hamsters-facts--blur {
            .hamsters-facts--page {
                width: 90%;
                .hamsters-facts--title {
                    font-size: 24px;
                    padding-top: 40px;
                    text-align: center;
                }
                .hamsters-facts--accordions {
                    width: 100%;
                    gap: 15px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
                
            }
            .blurred-things {
                position: relative;
                bottom: 500px;
                max-width: 100%;
                .cylinder--1 {
                    left: 0px;
                    top: 0px;
                    scale: 70%;
                }
                .cylinder--2 {
                    left: 0px;
                    top: 0px;
                    scale: 70%;
                }
                .triangle--1 {
                    left: 0px;
                    top: -150px;
                    scale: 50%;
                }
            }
        }

    }

    .login-modal {
        .login-modal--title {
            font-size: 30px;
            margin-top: 10px;
            margin-left: 10px;
        }
    }
}
